import * as clientsActions from '../../actions/clientsActions/constants';

const initialState = {
    clients: null,
    client: {
        personalInfo: null,
        documents: null,
        header: null,
        investments: null,
        statistics: null,
        bankDetails: null,
        comments: null,
        transactions: null,
        payments: null,
        referrals: null
    },
    isLoading: true,
    isLoadingRef: false,
    isTrxLoading: true,
    isProceedComments: false,
    isDeleteProceed: false,
    isEditComment: false,
    isReferralsLoading: true,
    isClientsProceed: false,
    isProceedReferrer: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case clientsActions.ASSIGN_SALES_REQUEST:
            return {
                ...state,
                isClientsProceed: true
            };
        case clientsActions.ASSIGN_SALES_SUCCESS:
            return {
                ...state,
                isClientsProceed: false
            };
        case clientsActions.ASSIGN_SALES_FAILURE:
            return {
                ...state,
                isClientsProceed: false
            };
        case clientsActions.SET_CLIENTS_LIST_DEFAULT:
            return {
                ...state,
                isLoading: true,
                isLoadingRef: false,
                clients: null
            };
        case clientsActions.GET_ALL_CLIENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
                isLoadingRef: true,
            };
        case clientsActions.GET_ALL_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: action.payload,
                isLoading: false,
                isLoadingRef: false,
            };
        case clientsActions.GET_ALL_CLIENTS_FAILURE:
            return {
                ...state,
                clients: null,
                isLoading: false,
                isLoadingRef: false,
            };
        case clientsActions.GET_CLIENT_HEADER_REQUEST:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_HEADER_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    header: action.payload
                },
            };
        case clientsActions.GET_CLIENT_HEADER_FAILURE:
            return {
                ...state
            };
        case clientsActions.GET_COMMENTS_REQUEST:
            return {
                ...state
            };
        case clientsActions.GET_COMMENTS_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    comments: action.payload
                },
            };
        case clientsActions.GET_COMMENTS_FAILURE:
            return {
                ...state
            };
        case clientsActions.ADD_COMMENT_REQUEST:
            return {
                ...state,
                isProceedComments: true
            };
        case clientsActions.ADD_COMMENT_SUCCESS:
            return {
                ...state,
                isProceedComments: false
            };
        case clientsActions.ADD_COMMENT_FAILURE:
            return {
                ...state,
                isProceedComments: false
            };
        case clientsActions.EDIT_COMMENT_REQUEST:
            return {
                ...state,
                isEditComment: true
            };
        case clientsActions.EDIT_COMMENT_SUCCESS:
            return {
                ...state,
                isEditComment: false
            };
        case clientsActions.EDIT_COMMENT_FAILURE:
            return {
                ...state,
                isEditComment: false
            };
        case clientsActions.DELETE_COMMENT_REQUEST:
            return {
                ...state,
                isDeleteProceed: true
            };
        case clientsActions.DELETE_COMMENT_SUCCESS:
            return {
                ...state,
                isDeleteProceed: false
            };
        case clientsActions.DELETE_COMMENT_FAILURE:
            return {
                ...state,
                isDeleteProceed: false
            };
        case clientsActions.GET_CLIENT_STATISTICS_REQUEST:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_STATISTICS_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    statistics: action.payload
                },
            };
        case clientsActions.GET_CLIENT_STATISTICS_FAILURE:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_PROFILE_REQUEST:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_PROFILE_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    personalInfo: action.payload
                },
            };
        case clientsActions.GET_CLIENT_PROFILE_FAILURE:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_DOCUMENTS_REQUEST:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_DOCUMENTS_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    documents: action.payload
                },
            };
        case clientsActions.GET_CLIENT_DOCUMENTS_FAILURE:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_PAYMENTS_REQUEST:
            return {
                ...state,
                isTrxLoading: true
            };
        case clientsActions.GET_CLIENT_PAYMENTS_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    payments: action.payload
                },
                isTrxLoading: false
            };
        case clientsActions.GET_CLIENT_PAYMENTS_FAILURE:
            return {
                ...state,
                client: {
                    ...state.client,
                    payments: null
                },
                isTrxLoading: false
            };
        case clientsActions.GET_CLIENT_REFERRALS_REQUEST:
            return {
                ...state,
                isReferralsLoading: true
            };
        case clientsActions.GET_CLIENT_REFERRALS_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    referrals: action.payload
                },
                isReferralsLoading: false
            };
        case clientsActions.GET_CLIENT_REFERRALS_FAILURE:
            return {
                ...state,
                client: {
                    ...state.client,
                    referrals: null
                },
                isReferralsLoading: false
            };
        case clientsActions.GET_CLIENT_TRANSACTIONS_REQUEST:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    transactions: action.payload
                },
            };
        case clientsActions.GET_CLIENT_TRANSACTIONS_FAILURE:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_BANK_DETAILS_REQUEST:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    bankDetails: action.payload
                },
            };
        case clientsActions.GET_CLIENT_BANK_DETAILS_FAILURE:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_PROFILE_COMMENTS_REQUEST:
            return {
                ...state
            };
        case clientsActions.GET_CLIENT_PROFILE_COMMENTS_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    comments: action.payload
                },
            };
        case clientsActions.GET_CLIENT_PROFILE_COMMENTS_FAILURE:
            return {
                ...state
            };
        case clientsActions.CLEAR_CLIENT_DATA:
            return {
                ...state,
                client: {
                    ...state.client,
                    personalInfo: null,
                    documents: null,
                    header: null,
                    investments: null,
                    statistics: null,
                    bankDetails: null,
                    comments: null,
                    transactions: null
                }
            };
        default:
            return state
    }
}